import './App.css';

function App() {
  return (
    <div className="card">
      <div className="content">
        <div className="title-holder">
          <h1>Bersiaplah untuk sesuatu yang luar biasa</h1>
          <p>Situs web segera hadir. Periksa lagi nanti untuk mengetahui lebih lanjut.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
